<template>
    <div class="booth-hall">
          <!-- page6 S
    B - 중외제약, GC녹십자
    F - 아주약품, 보령제약 -->
      <iframe loading="lazy" src="https://player.vimeo.com/video/679407888?h=06763f5beb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class='booth-video-bg'></iframe>

      <div class="m-f-2-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-F-2-5.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/684542128?h=2a3aa6af76&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-2-5"></iframe>

        <div class="logo-box-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/aju-logo.png" class="logo-m-f-2-5">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=158" target="_blank" class="link-m-f-2-5"></a>
      </div>

      <div class="m-f-4-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-F-4-5.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/685288400?h=9b3960b585&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-4-5"></iframe>

        <div class="logo-box-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/br-logo.jpg" class="logo-m-f-4-5">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=157" target="_blank" class="link-m-f-4-5"></a>
      </div>


      <div class="m-b-3-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-B-3-7-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/683177314?h=3470215587&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-3-7"></iframe>

        <div class="logo-box-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/jw-logo.jpg" class="logo-m-b-3-7">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=182" target="_blank" class="link-m-b-3-7"></a>
      </div>


      <div class="m-b-5-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-B-5-7-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/683177251?h=2ea049b095&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-5-7"></iframe>

        <div class="logo-box-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/gc-logo.png" class="logo-m-b-5-7">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=154" target="_blank" class="link-m-b-5-7"></a>
      </div>
    </div>
</template>

<script>

export default {
  name: 'ExhibitionHall6',
}
</script>



