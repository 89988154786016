<template>
  <div>
    <div class='handle-page-size-difference exhibition-hall relative'>
      <div class='flex flex-row justify-start lg:justify-center items-center gap-x-1 mt-8 text-sm px-4'>
        <button 
          @click='setHallNumber(1)'
          class='hall-button whitespace-nowrap px-4 lg:px-10 py-3 uppercase rounded hover:bg-gray-100'
          :class='isSelectedHall(1)'
          :style='isSelectedBackgroundColor(1)'>
          다이아몬드·플래티넘·골드플러스
        </button>
        <button 
          @click='setHallNumber(2)'
          class='hall-button whitespace-nowrap px-4 lg:px-16 py-3 uppercase rounded hover:bg-gray-100'
          :class='isSelectedHall(2)'
          :style='isSelectedBackgroundColor(2)'>
          골드 I
        </button>
        <button 
          @click='setHallNumber(3)'
          class='hall-button whitespace-nowrap px-4 lg:px-16 py-3 uppercase rounded hover:bg-gray-100'
          :class='isSelectedHall(3)'
          :style='isSelectedBackgroundColor(3)'>
          골드 II
        </button>
        <button 
          @click='setHallNumber(4)'
          class='hall-button whitespace-nowrap px-4 lg:px-16 py-3 uppercase rounded hover:bg-gray-100'
          :class='isSelectedHall(4)'
          :style='isSelectedBackgroundColor(4)'>
          실버
        </button>
        <button 
          @click='setHallNumber(5)'
          class='hall-button whitespace-nowrap px-4 lg:px-16 py-3 uppercase rounded hover:bg-gray-100'
          :class='isSelectedHall(5)'
          :style='isSelectedBackgroundColor(5)'>
          브론즈 I
        </button>
        <button 
          @click='setHallNumber(6)'
          class='hall-button whitespace-nowrap px-4 lg:px-16 py-3 uppercase rounded hover:bg-gray-100'
          :class='isSelectedHall(6)'
          :style='isSelectedBackgroundColor(6)'>
          브론즈 II
        </button>
      </div>
      <div class='relative'>
        <button v-if='hallNumber !== 1'
          class='go-left-button absolute text-white rounded-full border-2 border-solid border-white shadow hover:shadow-xl'
          style='z-index: 200; top:calc(50% - 0.5rem);'
          @click='hallNumberIncrement(-1)'>
          <arrow-narrow-left-icon class='h-12 w-12' />
        </button>
        <button v-if='hallNumber !== 6'
          class='go-right-button absolute text-white rounded-full border-2 border-solid border-white shadow hover:shadow-xl'
          style='z-index: 200; top:calc(50% - 0.5rem);'
          @click='hallNumberIncrement(1)'>
          <arrow-narrow-right-icon class='h-12 w-12' />
        </button>
        <component :is='whichHall' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions }                    from 'vuex'
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from 'vue-tabler-icons'

import ExhibitionHall1 from '@/components/exhibition-halls/ExhibitionHall1.vue'
import ExhibitionHall2 from '@/components/exhibition-halls/ExhibitionHall2.vue'
import ExhibitionHall3 from '@/components/exhibition-halls/ExhibitionHall3.vue'
import ExhibitionHall4 from '@/components/exhibition-halls/ExhibitionHall4.vue'
import ExhibitionHall5 from '@/components/exhibition-halls/ExhibitionHall5.vue'
import ExhibitionHall6 from '@/components/exhibition-halls/ExhibitionHall6.vue'


export default {
  name: 'ExhibitionHall',
  components: {
    ExhibitionHall1,
    ExhibitionHall2,
    ExhibitionHall3,
    ExhibitionHall4,
    ExhibitionHall5,
    ExhibitionHall6,
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
  },
  data () {
    return {
      hallNumber: 1,
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    whichHall () {
      return `exhibition-hall-${this.hallNumber}`
    },
  },
  methods: {
    ...mapActions('sponsors', [
      'getSponsors',
    ]),
    setHallNumber (hall) {
      this.hallNumber = hall
    },
    isSelectedHall (hall) {
      return (this.hallNumber === hall) ? 'font-semibold border text-white' : ''
    },
    isSelectedBackgroundColor (hall) {
      return (this.hallNumber === hall) ? `background-color: ${this.eventMainThemeColor}` : ''
    },
    hallNumberIncrement (increment) {
      this.hallNumber += increment
    },
  },
  mounted () {
    this.getSponsors('ExhibitionHall') // used so that we can forward to login page if not logged in
  },
}
</script>

<style lang='scss'>
  .exhibition-hall {
    margin-left: 0;
    width: 1440px;
    max-width: 1440px;


    .go-left-button {
      left: 1rem;
    }

    .go-right-button {
      right: 1rem;
    }

    .booth-hall {
      position: relative;
      width: 1440px;
      max-width: 1440px;
      // left: max(0px, calc((100% - 1440px)/2));
      height: 810px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .booth-video-bg {
      position: absolute;
      top:0;
      left:0;
      width: 1440px;
      height: 810px;
    }

    .booth-image {
      position: absolute;
      top: 0; 
      left: 0;
      max-width: 1440px;  
    }

    .banner-image {
      width: 100%;
    }

    iframe {
      box-sizing: content-box;
    }

    /*L-F-2-3*/
    .logo-box-l-f-2-3 {
      width: 112px;
      height: 36px;
      top: 520px;
      left: 660px;
      position: absolute;
      justify-content: center;
      display: flex;
    }
    .logo-l-f-2-3 {
      max-width: 112px;
      max-height: 36px;
      transform: skewX(0deg);
      align-self: center;
    }

    .tv-l-f-2-3 {  
      transform: skewX(0deg);
      border: #2C2C2B ridge 4px;
      width: 149px;
      height: 84px;
      top: 362px;
      left: 640px;
      position: absolute;
      background-color: black;
    }
    .left-banner-l-f-2-3 {
      border-radius: 8px;
      width: 70px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: 412px;
      left: 417px;
      transform: skewX(5deg) skewy(-11deg);
      text-align: center;
    }
    .left-main-l-f-2-3 {
      width: 100px;
      height: 60px;
      overflow: hidden;
      position: absolute;
      top: 387px;
      left: 527px;
      transform: skewx(3deg) skewY(0deg);
    }
    .right-main-l-f-2-3 {
      width: 100px;
      height: 60px;
      overflow: hidden;
      position: absolute;
      top: 387px;
      left: 813px;
      transform: skewx(-2deg);
    }
    .right-banner-l-f-2-3 {
      border-radius: 5px;
      width: 69px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: 416px;
      left: 954px;
      transform: skewX(-5deg) skewy(11deg);
    }
    .link-l-f-2-3 {
      width: 620px;
      height: 210px;
      top: 361px;
      left: 410px;
      position: absolute;
      z-index: 99;
    }


    /*L-B-2-5*/
    .logo-box-l-b-2-5 {
      width: 80px;
      height: 26px;
      position: absolute;
      top: 280px;
      left: 392px;
      justify-content: center;
      display: flex;
    }
    .logo-l-b-2-5 {
      max-width: 80px;
      max-height: 38px;
      transform: skewX(4deg);
      align-self: center;
    }
    .tv-l-b-2-5 {
      transform: skewX(5deg);
      border: #2C2C2B ridge 4px;
      width: 114px;
      height: 65px;
      top: 171px;
      left: 395px;
      position: absolute;
      background-color: black;
    }
    .left-banner-l-b-2-5 {
      border-radius: 4px;
      width: 52px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 203px;
      left: 212px;
      transform: skewX(8deg) skewy(-6deg);
    }
    .left-main-l-b-2-5 {
      width: 78px;
      height: 47px;
      overflow: hidden;
      position: absolute;
      top: 192px;
      left: 307px;
      transform: skewx(6deg) skewY(0deg);
    }
    .right-main-l-b-2-5 {
      width: 78px;
      height: 47px;
      overflow: hidden;
      position: absolute;
      top: 192px;
      left: 529px;
      transform: skewx(4deg) skewY(0deg);
    }
    .right-banner-l-b-2-5 {
      border-radius: 3px;
      width: 38px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 203px;
      left: 623px;
      transform: skewX(2deg) skewy(8deg);
    }
    .link-l-b-2-5 {
      width: 460px;
      height: 160px;
      top: 160px;
      left: 205px;
      position: absolute;
      z-index: 99;
    }



    /*L-B-4-5*/
    .logo-box-l-b-4-5 {
      width: 80px;
      height: 26px;
      position: absolute;
      top: 281px;
      left: 961px;
      justify-content: center;
      display: flex;
    }
    .logo-l-b-4-5 {
      max-width: 80px;
      max-height: 26px;
      transform: skewX(-5deg);
      align-self: center;
    }
    .tv-l-b-4-5 {
      transform: skewX(-4deg);
      border: #2C2C2B ridge 4px;
      width: 114px;
      height: 65px;
      top: 171px;
      left: 921px;
      position: absolute;
      background-color: black;
    }
    .left-banner-l-b-4-5 {
      border-radius: 3px;
      width: 38px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 203px;
      left: 772px;
      transform: skewX(-2deg) skewy(-6deg);
    }
    .left-main-l-b-4-5 {
      width: 78px;
      height: 47px;
      overflow: hidden;
      position: absolute;
      top: 192px;
      left: 832px;
      transform: skewx(-4deg) skewY(0deg);
    }
    .right-main-l-b-4-5 {
      width: 78px;
      height: 47px;
      overflow: hidden;
      position: absolute;
      top: 192px;
      left: 1055px;
      transform: skewx(-7deg) skewY(0deg);
    }
    .right-banner-l-b-4-5 {
      border-radius: 3px;
      width: 52px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 203px;
      left: 1173px;
      transform: skewX(-7deg) skewy(6deg);
    }
    .link-l-b-4-5 {
      width: 460px;
      height: 160px;
      top: 160px;
      left: 765px;
      position: absolute;
      z-index: 99;
    }


    /*m-f-2-5*/
    .logo-box-m-f-2-5 {
      width: 92px;
      height: 36px;
      position: absolute;
      top: 514px;
      left: 346px;
      justify-content: center;
      display: flex;
    }
    .logo-m-f-2-5 {
      max-width: 92px;
      max-height: 36px;
      transform: skewX(6deg);
      align-self: center;
    }
    .tv-m-f-2-5 {
      transform: skewX(6deg);
      border: #2C2C2B ridge 4px;
      width: 149px;
      height: 84px;
      top: 361px;
      left: 344px;
      position: absolute;
      background-color: black;
    }
    .left-banner-m-f-2-5 {
      border-radius: 8px;
      width: 80px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: 411px;
      left: 173px;
      transform: skewX(11deg) skewy(-8deg);
    }
    .right-banner-m-f-2-5 {
      border-radius: 5px;
      width: 57px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: 412px;
      left: 559px;
      transform: skewX(3deg) skewy(14deg);
    }
    .link-m-f-2-5 {
      width: 460px;
      height: 210px;
      top: 361px;
      left: 165px;
      position: absolute;
      z-index: 99;
    }


    /*m-f-4-5*/
    .logo-box-m-f-4-5 {
      width: 92px;
      height: 36px;
      position: absolute;
      top: 514px;
      left: 996px;
      justify-content: center;
      display: flex;
    }
    .logo-m-f-4-5 {
      max-width: 92px;
      max-height: 36px;
      transform: skewX(-7deg);
      align-self: center;
    }
    .tv-m-f-4-5 {
      transform: skewX(-5deg);
      border: #2C2C2B ridge 4px;
      width: 149px;
      height: 84px;
      top: 361px;
      left: 932px;
      position: absolute;
      background-color: black;
    }
    .left-banner-m-f-4-5 {
      border-radius: 5px;
      width: 57px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: 411px;
      left: 815px;
      transform: skewX(-2deg) skewy(-11deg);
    }
    .right-banner-m-f-4-5 {
      border-radius: 8px;
      width: 80px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: 412px;
      left: 1183px;
      transform: skewX(-10deg) skewy(9deg);
    }
    .link-m-f-4-5 {
      width: 460px;
      height: 210px;
      top: 361px;
      left: 810px;
      position: absolute;
      z-index: 99;
    }


    /*M-B-3-7*/
    .logo-box-m-b-3-7 {
      width: 69px;
      height: 25px;
      position: absolute;
      top: 278px;
      left: 505px;
      justify-content: center;
      display: flex;
    }
    .logo-m-b-3-7 {
      max-width: 69px;
      max-height: 25px;
      transform: skewX(4deg);
      align-self: center;
    }
    .tv-m-b-3-7 {
      transform: skewX(2deg);
      border: #2C2C2B ridge 4px;
      width: 114px;
      height: 65px;
      top: 170px;
      left: 489px;
      position: absolute;
      background-color: black;
    }
    .left-banner-m-b-3-7 {
      border-radius: 4px;
      width: 52px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 203px;
      left: 374px;
      transform: skewX(5deg) skewy(-6deg);
    }
    .right-banner-m-b-3-7 {
      border-radius: 3px;
      width: 38px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 203px;
      left: 667px;
      transform: skewX(1deg) skewy(8deg);
    }
    .link-m-b-3-7 {
      width: 340px;
      height: 160px;
      top: 160px;
      left: 370px;
      position: absolute;
      z-index: 99;
    }

    /*M-B-5-7*/
    .logo-box-m-b-5-7 {
      width: 69px;
      height: 25px;
      position: absolute;
      top: 282px;
      left: 871px;
      justify-content: center;
      display: flex;
    }
    .logo-m-b-5-7 {
      max-width: 69px;
      max-height: 25px;
      transform: skewX(-2deg);
      align-self: center;
    }
    .tv-m-b-5-7 {
      transform: skewX(-2deg);
      border: #2C2C2B ridge 4px;
      width: 114px;
      height: 65px;
      top: 171px;
      left: 833px;
      position: absolute;
      background-color: black;
    }
    .left-banner-m-b-5-7 {
      border-radius: 3px;
      width: 38px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 204px;
      left: 740px;
      transform: skewX(-1deg) skewy(-6deg);
    }
    .right-banner-m-b-5-7 {
      border-radius: 3px;
      width: 52px;
      height: 65px;
      overflow: hidden;
      position: absolute;
      top: 204px;
      left: 1018px;
      transform: skewX(-5deg) skewy(6deg);
    }
    .link-m-b-5-7 {
      width: 340px;
      height: 160px;
      top: 160px;
      left: 735px;
      position: absolute;
      z-index: 99;
    }
  }
  @media (min-width: 1280px) { 
    .handle-page-size-difference {
      margin-left: -80px; // this page is 1440, need to move it half the difference
    }

    .go-left-button {
      left: 1rem;
    }

    .go-right-button {
      right: -120px;
    }
  }

</style>



