<template>
    <div class="booth-hall">
       <!--   page2 M
    B - 종근당, 한독
    F - 대웅제약, 유한양행 -->
      <iframe loading="lazy" src="https://player.vimeo.com/video/679407888?h=06763f5beb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class='booth-video-bg'></iframe>
      
      <div class="m-f-2-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-F-2-5.png" width="1440px" class='booth-image'>
        <iframe loading="lazy" src="https://player.vimeo.com/video/683175493?h=30e01c40b2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-2-5"></iframe>
        <div class="logo-box-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/dw-logo.jpg" class="logo-m-f-2-5">
        </div>
        <div class="left-banner-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/dw-r-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/dw-l-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=178" target="_blank" class="link-m-f-2-5"></a>
      </div>

      <div class="m-f-4-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-F-4-5.png" width="1440px" class='booth-image'>
        <iframe loading="lazy" src="https://player.vimeo.com/video/683175379?h=d5f6b4a9ce&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-4-5"></iframe>
        <div class="logo-box-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/yuhan-logo.jpg" class="logo-m-f-4-5">
        </div>
        <div class="left-banner-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/yuhan-l-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/yuhan-r-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=177" target="_blank" class="link-m-f-4-5"></a>
      </div>


      <div class="m-b-3-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-B-3-7-V2.png" width="1440px" class='booth-image'>
        <iframe loading="lazy" src="https://player.vimeo.com/video/683175564?h=0412587579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-3-7"></iframe>
        <div class="logo-box-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/jkd-logo.png" class="logo-m-b-3-7">
        </div>
        <div class="left-banner-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/jgd-r-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/jgd-l-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=176" target="_blank" class="link-m-b-3-7"></a>
      </div>


      <div class="m-b-5-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-B-5-7-V2.png" width="1440px" class='booth-image'>
        <iframe loading="lazy" src="https://player.vimeo.com/video/683175727?h=239e39b682&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-5-7"></iframe>
        <div class="logo-box-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hd-logo.png" class="logo-m-b-5-7">
        </div>
        <div class="left-banner-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hd-l-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hd-r-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=175" target="_blank" class="link-m-b-5-7"></a>
      </div>
    </div>
</template>

<script>

export default {
  name: 'ExhibitionHall2',
}
</script>



