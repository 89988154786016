<template>
    <div class="booth-hall">
        <!--   page5 S
    B - 다이이찌, 한국베링거인겔하임
    F - 다림바이오텍, 광동제약 -->
      <iframe loading="lazy" src="https://player.vimeo.com/video/679407888?h=06763f5beb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class='booth-video-bg'></iframe>

      <div class="m-f-2-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-F-2-5.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/684069298?h=dc87bae49d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-2-5"></iframe>

        <div class="logo-box-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/dalim-logo.jpg" class="logo-m-f-2-5">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=166" target="_blank" class="link-m-f-2-5"></a>
      </div>

      <div class="m-f-4-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-F-4-5.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/683177371?h=ccd048bbbe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-4-5"></iframe>

        <div class="logo-box-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/kd-logo.jpg" class="logo-m-f-4-5">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=164" target="_blank" class="link-m-f-4-5"></a>
      </div>


      <div class="m-b-3-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-B-3-7-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/684059192?h=65c3612cab&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-3-7"></iframe>

        <div class="logo-box-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/daiichi-logo.jpg" class="logo-m-b-3-7">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=161" target="_blank" class="link-m-b-3-7"></a>
      </div>


      <div class="m-b-5-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-B-5-7-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/683177110?h=df57f70ca1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-5-7"></iframe>

        <div class="logo-box-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/bi-logo.png" class="logo-m-b-5-7">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=159" target="_blank" class="link-m-b-5-7"></a>
      </div>
    </div>
</template>

<script>

export default {
  name: 'ExhibitionHall5',
}
</script>



