<template>
    <div class="booth-hall">
          <!-- page3 M
    B - AZ, HK이노엔
    F - 동아ST, 한미약품 -->
      <iframe loading="lazy" src="https://player.vimeo.com/video/679407888?h=06763f5beb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class='booth-video-bg'></iframe>
      
      <div class="m-f-2-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-F-2-5.png" width="1440px" class='booth-image'>
        <iframe loading="lazy" src="https://player.vimeo.com/video/684128175?h=10f4f39fe5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-2-5"></iframe>
        <div class="logo-box-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/da-logo.jpg" class="logo-m-f-2-5">
        </div>
        <div class="left-banner-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/da-r-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hk-l-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=174" target="_blank" class="link-m-f-2-5"></a>
      </div>

      <div class="m-f-4-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-F-4-5.png" width="1440px" class='booth-image'>

        <iframe loading="lazy" src="https://player.vimeo.com/video/683175520?h=f4e6fc4a82&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-4-5"></iframe>

        <div class="logo-box-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hm-logo.jpg" class="logo-m-f-4-5">
        </div>
        <div class="left-banner-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hm-l-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hm-r-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=173" target="_blank" class="link-m-f-4-5"></a>
      </div>


      <div class="m-b-3-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-B-3-7-V2.png" width="1440px" class='booth-image'>

        <iframe loading="lazy" src="https://player.vimeo.com/video/683175451?h=927c03c6c3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-3-7"></iframe>

        <div class="logo-box-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/az-logo.jpg" class="logo-m-b-3-7">
        </div>
        <div class="left-banner-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/az-r-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/az-l-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=172" target="_blank" class="link-m-b-3-7"></a>
      </div>


      <div class="m-b-5-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/M-B-5-7-V2.png" width="1440px" class='booth-image'>
        <iframe loading="lazy" src="https://player.vimeo.com/video/685284736?h=80bea461c3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-5-7"></iframe>
        <div class="logo-box-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hk-logo.jpg" class="logo-m-b-5-7">
        </div>
        <div class="left-banner-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hk-l-b.jpg" class='banner-image'>
        </div>
        <div class="right-banner-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/hk-r-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=171" target="_blank" class="link-m-b-5-7"></a>
      </div>
    </div>
</template>

<script>

export default {
  name: 'ExhibitionHall3',
}
</script>



