<template>
    <div class="booth-hall">
      <!--   Large
      B - 노보노디스크제약, MSD
      F - 알보젠 코리아 -->
      <iframe loading="lazy" src="https://player.vimeo.com/video/679407888?h=06763f5beb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class='booth-video-bg'></iframe>

      <div class="l-f-2-3">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/L-F-2-3.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/684127096?h=0c2814e7b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-l-f-2-3"></iframe>

        <div class="logo-box-l-f-2-3">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/alvogen-logo.jpg" class="logo-l-f-2-3">
        </div>
        <div class="left-banner-l-f-2-3">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/alvogen-l-b-v2.jpg" class='banner-image'>
        </div>
        <div class= "left-main-l-f-2-3">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/alvogen-r-m.jpg" class='banner-image'>
        </div>
        <div class="right-main-l-f-2-3">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/alvogen-r-m.jpg" class='banner-image'>
        </div>
        <div class="right-banner-l-f-2-3">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/alvogen-r-b-v2.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=181" target="_blank" class="link-l-f-2-3"></a>
      </div>


      <div class="l-b-2-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/L-B-2-5-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/686544101?h=021a950802&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-l-b-2-5"></iframe>

        <div class="logo-box-l-b-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/novonor-logo-v2.png" class="logo-l-b-2-5">
        </div>
        <div class="left-banner-l-b-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/novonor-r-b.jpg" class='banner-image'>
        </div>
        <div class= "left-main-l-b-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/novonor-l-m.jpg" class='banner-image'>
        </div>
        <div class="right-main-l-b-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/novonor-r-m.jpg" class='banner-image'>
        </div>
        <div class="right-banner-l-b-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/novonor-l-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=180" target="_blank" class="link-l-b-2-5"></a>
      </div>


      <div class="l-b-4-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/L-B-4-5-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/683175597?h=424d9157e3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-l-b-4-5"></iframe>

        <div class="logo-box-l-b-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/msd-logo.jpg" class="logo-l-b-4-5">
        </div>
        <div class="left-banner-l-b-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/msd-l-b.jpg" class='banner-image'>
        </div>
        <div class= "left-main-l-b-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/msd-l-m.jpg" class='banner-image'>
        </div>
        <div class="right-main-l-b-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/msd-r-m.jpg" class='banner-image'>
        </div>
        <div class="right-banner-l-b-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/msd-r-b.jpg" class='banner-image'>
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=179" target="_blank" class="link-l-b-4-5"></a>
      </div>
    </div>
</template>

<script>
  
export default {
  name: 'ExhibitionHall1',
}
</script>



