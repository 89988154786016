<template>
    <div class="booth-hall">
          <!-- page4 S
    B - 사노피-아프로벨, 오가논
    F - 셀트리온, LG화학 -->
      <iframe loading="lazy" src="https://player.vimeo.com/video/679407888?h=06763f5beb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class='booth-video-bg'></iframe>

      <div class="m-f-2-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-F-2-5.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/683177206?h=13ab241f71&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-2-5"></iframe>

        <div class="logo-box-m-f-2-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/celltrion-logo-v2.png" class="logo-m-f-2-5">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=170" target="_blank" class="link-m-f-2-5"></a>
      </div>

      <div class="m-f-4-5">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-F-4-5.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/684079365?h=cf0bb4bc1a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-f-4-5"></iframe>

        <div class="logo-box-m-f-4-5">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/lg-logo.jpg" class="logo-m-f-4-5">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=169" target="_blank" class="link-m-f-4-5"></a>
      </div>


      <div class="m-b-3-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-B-3-7-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/683177412?h=2b2dd607f9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-3-7"></iframe>

        <div class="logo-box-m-b-3-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/sanofi-logo.jpg" class="logo-m-b-3-7">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=168" target="_blank" class="link-m-b-3-7"></a>
      </div>


      <div class="m-b-5-7">
        <img src="https://d3r2ol85dktaw0.cloudfront.net/booth/S-B-5-7-V2.png" width="1440px" class='booth-image'>
        
        <iframe loading="lazy" src="https://player.vimeo.com/video/684074454?h=36fc1b6d8a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1" frameborder="0" allow="autoplay;" class="tv-m-b-5-7"></iframe>

        <div class="logo-box-m-b-5-7">
          <img src="https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/sponsors/booth_images/organon-logo.png" class="logo-m-b-5-7">
        </div>
        <a href="https://kossoasc.org/sponsor?sponsor_id=167" target="_blank" class="link-m-b-5-7"></a>
      </div>
    </div>

</template>

<script>

export default {
  name: 'ExhibitionHall4',
}
</script>



